import BookingForm from './booking-form';
import BookingService from './booking-service';
import BookingServiceFactory from './booking-service-factory';
import RedirectBookingService from './redirect-booking-service';


export default (di) => {
	di
		.setType({type: BookingForm, name: 'BookingForm', parent: 'PageComponent', setters: {
				injectBookingServiceFactory: di.lazyGet('booking/serviceFactory')
			}
		})
		.setType({type: BookingService, name: 'BookingService', mixins: ['navigationMixin']})


		.setType({type: BookingServiceFactory, name: 'BookingServiceFactory', parent: 'Factory', params: {
				suffix: 'BookingService'
			}
		})
		.set('booking/serviceFactory', di.lazyNew('BookingServiceFactory'))

		.setType({type: RedirectBookingService, name: 'RedirectBookingService', parent: 'BookingService'})
	;
};
