import PageComponent from '../../common/component/page-component';

class MenuToggler extends PageComponent {
	constructor({
		root,
		element,
		mediaQuery,
		contextName = 'menu',
		closeClass = 'close'
	}) {
		super({root: root, element: element});
		this.mediaQuery = mediaQuery;
		this.contextName = contextName;
		this.closeClass = closeClass;
	}

	prepare() {
		this.listeners.click = this.events.on(this.element, 'click', this.onToggle.bind(this));
		this.listeners.pageLoad = this.events.on(window, 'history:pagechange', this.onPageLoad.bind(this));
		this.listeners.context = this.events.on(this.root, 'context:switchcomplete', this.onContextSwitch.bind(this));
	}


	onContextSwitch(event) {
		this.classList(this.element).toggle(this.closeClass, event.detail.currentContext.getName() === this.contextName);
	}


	onToggle(event, target) {
		event.preventDefault();
		this.classList(this.element).toggle(this.closeClass, this.contexts.getCurrentContext().getName() !== this.contextName);
		this.contexts.toggle(this.contextName);
	}


	onPageLoad(e) {
		this.classList(this.element).remove(this.closeClass);
	}
}

export default MenuToggler;
