import domMixin from '../dom/dom-mixin';
import pageConstants from './_constants';


class PageSlot extends domMixin() {

	constructor({name, root}) {
		super();
		this.name = name;
		this.root = root;
		this.element = null;
		this.currentPage = null;
	}


	getName() {
		return this.name;
	}


	getElement() {
		if (!this.element || !document.body.contains(this.element)) {
			this.element = this.root.querySelector(this.dataSelector(pageConstants.slotAttribute, this.name));
		}
		return this.element;
	}


	getCurrentPage() {
		return this.currentPage;
	}


	setCurrentPage(page) {
		if (this.currentPage) {
			this.detach(this.currentPage.getElement());
		}
		this.currentPage = page;
		return this;
	}


	unsetCurrentPage() {
		return this.setCurrentPage(null);
	}


	attach(element) {
		const parent = this.getElement();
		if ((!this.currentPage || this.currentPage.getElement() !== element) && element.parentNode !== parent) {
			const firstChild = parent.firstChild;
			if (firstChild) {
				parent.insertBefore(element, firstChild);
			} else {
				parent.appendChild(element);
			}
			// we need to recreate scripts that were eventually contained in the node, since just appending them is not enough to execute them
			const scripts = element.querySelectorAll('script');
			for (const node of scripts) {
				if (node instanceof HTMLScriptElement) {
					const script = document.createElement('script');
					for (const attribute of node.attributes) {
						script.setAttribute(attribute.name, attribute.value);
					}
					script.textContent = node.textContent;
					node.parentNode.insertBefore(script, node);
					node.parentNode.removeChild(node);
				}
			}
		}
		return this;
	}


	detach(element) {
		const parent = this.getElement();
		if (parent === element.parentNode) {
			parent.removeChild(element);
		}
		return this;
	}

}


export default PageSlot;
