import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
// import {isString} from '../utils/types';
import PageComponent from '../../common/component/page-component';

dayjs.extend(customParseFormat);

const DATE_INPUT_FORMAT = 'YYYY-MM-DD';

const names = {
	in: 'in',
	out: 'out',
	checkAttribute: 'check',
	checkInputAttribute: 'checkInput',
	checkLabelAttribute: 'checkLabel',
	checkLabelValueAttribute: 'checkLabelValue',
	calendarAttribute: 'calendar',
	formatAttribute: 'format',
	defaultTextAttribute: 'default',
	amountAttribute: 'amount',
	submitAttribute: 'submit',
	bookingServiceAttribute: 'bookingService',
	bookingServiceParamsAttribute: 'bookingServiceParams',
	changeEvent: 'booking:change',
	requestEvent: 'booking:request'
};


class BookingForm extends PageComponent {

	constructor({
		root,
		element,
		activeClass = 'active'

	}) {
		super({root: root, element: element});
		this.dayjs = dayjs;

		this.activeClass = activeClass;
		this.names = names;

		this.checks = new Map();
		this.calendars = new Map();
		this.labels = new Map();
		this.labelValues = new Map();
		this.inputs = new Map();
		this.interval = {};
		this.amount = 0;

		this.bookingService = null;
	}


	injectBookingServiceFactory(bookingServiceFactory) {
		this.bookingServiceFactory = bookingServiceFactory;
	}


	prepare() {
		// init items
		let first = true;
		let firstCalendar = null;
		for (const type of [names.in, names.out]) {
			this.checks.set(type, this.element.querySelector(this.dataSelector(names.checkAttribute, type)));
			this.labels.set(type, this.element.querySelector(this.dataSelector(names.checkLabelAttribute, type)));
			this.labelValues.set(type, this.element.querySelector(this.dataSelector(names.checkLabelValueAttribute, type)));
			this.inputs.set(type, this.element.querySelector(this.dataSelector(names.checkInputAttribute, type)));
			this.calendars.set(type, this.queryComponent(this.dataSelector(names.calendarAttribute, type)));
			this.interval[type] = null;
			if (first) {
				first = false;
				this.setActiveCheck(type);
				firstCalendar = this.calendars.get(type);
			}
		}

		this.amount = this.element.querySelector(this.dataSelector(names.amountAttribute));
		this.submit = this.element.querySelector(this.dataSelector(names.submitAttribute));

		this.dateFormat = firstCalendar.getDateFormat();
		this.calendarSelectEvent = firstCalendar.getSelectEvent();
		this.calendarChangeEvent = firstCalendar.getChangeEvent();
		this.dateAttribute = firstCalendar.getDateAttribute();


		// you cannot book something in the past, so we allow only present and future dates
		const today = dayjs().format(this.dateFormat);
		const tomorrow = dayjs().add(1, 'day').format(this.dateFormat);
		for (const [type, calendar] of this.calendars) {
			calendar.setAllowedInterval('[' + (type === names.in ? today : tomorrow) + ',)');
		}

		this.listeners.calendarSelect = this.events.on(this.element, this.calendarSelectEvent, this.onSelectDate.bind(this));
		this.listeners.calendarChange = this.events.on(this.element, this.calendarChangeEvent, this.onCalendarChange.bind(this));
		this.listeners.amountChange = this.events.on(this.element, this.dataSelector(names.amountAttribute), 'input', this.onAmountChange.bind(this));
		this.listeners.dateInputChange = this.events.on(this.element, this.dataSelector(names.checkInputAttribute), 'change', this.onDateInputChange.bind(this));
		this.listeners.labelsClick = this.events.on(this.element, this.dataSelector(names.checkLabelAttribute), 'click', this.onLabelClick.bind(this));
		this.listeners.submit = this.events.on(this.element, 'submit', this.onSubmit.bind(this));
	}


	onSelectDate(event) {
		const isCheckIn = event.detail.component === this.calendars.get(names.in);
		const day = event.detail.selectedTarget.closest(this.dataSelector(this.dateAttribute));
		const date = this.dataAttr(day).get(this.dateAttribute);
		this.selectDate(isCheckIn ? names.in : names.out, date);
	}


	onDateInputChange(event, target) {
		const dataAttr = this.dataAttr(target);
		const date = dayjs(target.value, DATE_INPUT_FORMAT).format(this.dateFormat);
		console.log('input date', date);
		this.selectDate(dataAttr.get(names.checkInputAttribute), date);
	}


	onAmountChange(event, target) {
		let amount = parseInt(target.value, 10);
		const changeEvent = this.events.trigger(this.element, names.changeEvent, {type: 'amount', value: amount, component: this});
		if (!changeEvent.defaultPrevented) {
			amount = changeEvent.detail.value;
			this.amount = amount;
			this.updateStatus();
		}
	}


	onLabelClick(event, target) {
		console.log('label click');
		this.setActiveCheck(this.dataAttr(target).get(names.checkLabelAttribute));
		event.preventDefault();
	}


	onCalendarChange(event) {
		const isCheckIn = event.detail.component === this.calendars.get(names.in);
		if (isCheckIn && event.detail.date > this.calendars.get(names.out).getMonthDate()) {
			this.calendars.get(names.out).update(event.detail.date);
		} else if (!isCheckIn && event.detail.date < this.calendars.get(names.in).getMonthDate()) {
			this.calendars.get(names.in).update(event.detail.date);
		}
	}


	onSubmit(event) {
		if (!this.canBeSubmitted()) {
			console.log('cannot be submitted');
			event.preventDefault();
		} else {
			let data = {};
			data[names.in] = this.interval[names.in];
			data[names.out] = this.interval[names.out];
			data.amount = this.amount;
			const requestEvent = this.events.trigger(this.element, names.requestEvent, {data: data, component: this});
			if (!requestEvent.defaultPrevented) {
				data = requestEvent.detail.data;
				const doSubmit = this.getBookingService().request(data, this);
				if (!doSubmit) {
					event.preventDefault();
				}
			} else {
				event.preventDefault();
			}
		}
	}


	getBookingService() {
		if (!this.bookingService) {
			const className = this.dataAttr().get(names.bookingServiceAttribute, null);
			if (!className) {
				throw new Error('BookingService not defined');
			}
			const params = this.dataAttr().get(names.bookingServiceParamsAttribute, {});
			this.bookingService = this.bookingServiceFactory.newInstance(className, params);
		}
		return this.bookingService;
	}


	getRequestEvent() {
		return names.requestEvent;
	}


	getChangeEvent() {
		return names.changeEvent;
	}


	selectDate(type, date) {
		const changeEvent = this.events.trigger(this.element, names.changeEvent, {type: type, value: date, component: this});
		if (!changeEvent.defaultPrevented) {
			date = changeEvent.detail.value;
			this.interval[type] = date;
			// if you try to select an invalid interval (in after out or viceversa) we reset the other value
			// so you are restarting a new selection
			if (type === names.in && this.interval[names.out] !== null && date >= this.interval[names.out]) {
				this.interval[names.out] = null;
			} else if (type === names.out && this.interval[names.in] !== null && date <= this.interval[names.in]) {
				this.interval[names.in] = null;
			}

			for (const [name, calendar] of this.calendars) {
				calendar.clearSelections();
				calendar.addSelection(this.interval[names.in], this.interval[names.out], name === names.in, name === names.out);
			}

			if (type === names.in) {
				this.setActiveCheck(names.out);
			}
			this.update(this.interval);
		}
	}


	setActiveCheck(type) {
		for (const [name, check] of this.checks) {
			this.classList(check).toggle(this.activeClass, type === name);
		}
	}


	update(interval) {
		for (const type of [names.in, names.out]) {
			const label = this.labelValues.get(type);
			const labelData = this.dataAttr(label);
			label.textContent = (
				interval[type] === null ?
				labelData.get(names.defaultTextAttribute) :
				dayjs(interval[type], this.dateFormat).format(labelData.get(names.formatAttribute))
			);

			this.inputs.get(type).value = (
				interval[type] === null ?
				'' :
				dayjs(interval[type], this.dateFormat).format(DATE_INPUT_FORMAT)
			);
		}
		this.updateStatus();
	}


	updateStatus() {
		this.submit.disabled = !this.canBeSubmitted();
	}


	canBeSubmitted() {
		return this.amount > 0 &&
			this.interval[names.in] !== null &&
			this.interval[names.out] !== null &&
			this.interval[names.in] < this.interval[names.out]
		;
	}

}


export default BookingForm;
