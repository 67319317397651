import AsyncPicture from './async-picture';
import VideoPlayerCover from './video-player-cover';
import VideoPlayer from './video-player';
import VideoPlayerDriver from './video-player-driver';
import VideoPlayerDriverFactory from './video-player-driver-factory';


const config = (di) => {
	di
		.setType({type: AsyncPicture, name: 'AsyncPicture', parent: 'PageComponent'})
		.setType({type: VideoPlayer, name: 'VideoPlayer', parent: 'PageComponent', setters: {
				injectVideoPlayerDriverFactory: di.lazyGet('media/videoPlayerDriverFactory')
			}
		})
		.setType({type: VideoPlayerCover, name: 'VideoPlayerCover', parent: 'PageComponent'})
		.setType({type: VideoPlayerDriver, name: 'VideoPlayerDriver'})
		.setType({type: VideoPlayerDriverFactory, name: 'VideoPlayerDriverFactory', params: {
				factory: di.lazyNew('Factory', {suffix: 'VideoPlayerDriver'})
			}
		})
		.set('media/videoPlayerDriverFactory', di.lazyNew('VideoPlayerDriverFactory'))
	;
};


export default config;
