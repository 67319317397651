import httpRequest from 'superagent';
import random from '../utils/random';
import Request from './request';
// import {fail} from 'assert';


class Api {
    constructor({events, requestFactory, baseUrl, sidName = 'sid', tokenName = 'csrfToken', timeout = 10000, dummyMinDuration = 100, dummyMaxDuration = 400}) {
		this.events = events;
		this.requestFactory = requestFactory;
        this.baseUrl = baseUrl;
		this.sidName = sidName;
        this.tokenName = tokenName;
        this.sid = null;
        this.token = null;
		this.dummyMinDuration = dummyMinDuration;
		this.dummyMaxDuration = dummyMaxDuration;
		this.lastPromise = null;

		this.defaultOptions = {
			timeout: this.timeout,
			dummyResponse: null,
			dummyError: null,
			serialize: false,
			files: [],
			onProgress: null,
		};

		this.defaultFormOptions = {
			method: 'POST',
			target: '_blank',
			fieldName: 'jsonData'
		};
	}


    execute(command, params = {}, options = {}) {
		const request = command instanceof Request ? command : this.createRequest(command, params, options);
		this.events.trigger(document.body, 'api:beforeCall', {request: request});
		this.lastPromise = new Promise(this.getPromiseExecutor(request));
		return this.lastPromise;
	}


	sendForm(command, params, options = {}) {
		command = this.prepareCommand(command);
		params = this.prepareParams(params);
		options = this.prepareFormOptions(options);

		const form = document.createElement('form');
		if (options.target) {
			form.target = options.target;
		}
		if (options.method) {
			form.method = options.method;
		}
		form.action = command;

		const input = document.createElement('input');
		input.type = 'hidden';
		input.name = options.fieldName;
		input.value = JSON.stringify(params);

		form.appendChild(input);
		form.style.display = 'none';
		document.body.appendChild(form);
		form.submit();
		setTimeout(() => {
			document.body.removeChild(form);
		}, 20);
	}


	createRequest(command, params, options = {}) {
		command = this.prepareCommand(command);
		params = this.prepareParams(params);
		options = this.prepareOptions(options);
		return this.requestFactory({api: this, command: command, params: params, options: options});
	}


	getPromiseExecutor(request) {
		return (resolve, reject) => {
			request.setTriggers(resolve, reject);

			const send = () => {
				if (request.isDummy()) {
					setTimeout(() => {
						request.processResponse();
					}, random(this.dummyMinDuration, this.dummyMaxDuration));
				} else {
					request.params = this.prepareParams(request.params);
					const remoteCall = httpRequest
						.post(request.command)
						.set('X-Requested-With', 'XMLHttpRequest')
						.set('Accept', 'application/json')
					;
					if (request.options.onProgress) {
						remoteCall.on('progress', request.options.onProgress);
					}
					if ('files' in request.options && Array.isArray(request.options.files) && request.options.files.length) {
						const json = JSON.stringify(request.params);
						remoteCall.field('jsonData', json);
						for (const entry of request.options.files) {
							remoteCall.attach(entry.name, entry.file);
						}
					} else {
						if (request.options.timeout > 0) {
							remoteCall.timeout(request.options.timeout);
						}
						remoteCall.send(request.params);
					}
					request.setRemoteCall(remoteCall);

					remoteCall.end((error, response) => {
						let body = (response && 'body' in response ? this.checkResponseApiInfo(response.body) : {});
						let eventName = 'api:';
						if (error || !response.ok) {
							if (!('response' in error)) {
								error.response = response;
							}
							request.setError(error);
							eventName += 'error';
						} else {
							body = this.prepareResponse(body);
							request.setResponse(body);
							eventName += 'complete';
						}
						const event = this.events.trigger(document.body, eventName, {request: request});
						if (!event.defaultPrevented) {
							request.processResponse();
						}
					});
				}
			};
			request.setAction(send);
			if (request.options.serialize && this.lastPromise) {
				this.lastPromise.then(() => {
					request.execute();
				});
			} else {
				request.execute();
			}
		};
	}


    prepareCommand(command) {
        return this.baseUrl + '/' + command;
    }


    prepareParams(params) {
		params = Object.assign({}, params);
        if (this.sid !== null) {
			params[this.sidName] = this.sid;
		}
		if (this.token !== null) {
            params[this.tokenName] = this.token;
		}
        return params;
	}


	prepareOptions(options) {
		return Object.assign({}, this.defaultOptions, options);
	}


	prepareFormOptions(options) {
		return Object.assign({}, this.defaultFormOptions, options);
	}


    checkResponseApiInfo(response) {
		if ('output' in response) {
			const output = response.output;
			if ('responses' in output && Array.isArray(output.responses)) {
				for (const res of output.responses) {
					this.checkResponseApiInfo(res);
				}
			}
	        if (this.sidName !== null && this.sidName in output) {
	            this.sid = output[this.sidName];
	        }
	        if (this.tokenName !== null && this.tokenName in output) {
	            this.token = output[this.tokenName];
	        }
		}
        return response;
	}


	prepareResponse(response) {
		return response;
	}

}


export default Api;
