// import SwapTransition from './swap-transition';
// import SwapTransitionFactory from './swap-transition-factory';

// import ParallaxBackgrounds from './parallax-backgrounds';
import IntersectionTriggers from './intersection-triggers';
// import IntersectionObservers from './intersection-observers';


export default (di) => {
	di
	// 	.setType({type: SwapTransition, name: 'SwapTransition', mixins: ['domMixin']})

	// 	.setType({type: SwapTransitionFactory, name: 'SwapTransitionFactory', parent: 'Factory'})
	// 	.set('animation/swapTransitionFactory')

	// 	.setType({type: ParallaxBackgrounds, name: 'ParallaxBackgrounds', mixins: ['domMixin']})
	.setType({type: IntersectionTriggers, name: 'IntersectionTriggers', mixins: ['domMixin']})
	// .setType({type: IntersectionObservers, name: 'IntersectionObservers'})
	// .set('animation/intersectionObservers', di.lazyNew('IntersectionObservers'))
	;
};
