import namedRegexp from 'named-js-regexp';
import {ucFirst} from '../utils/string';


class VideoPlayerDriverFactory {
	constructor({factory}) {
		this.factory = factory;
		this.types = new Map();

		// patterns to recognize the video provider by the URL
		this.types.set('youtube', {
			patterns: [
				'v=(?<videoId>[a-z0-9_-]+)',
				'youtube.com/embed/(?<videoId>[a-z0-9_-]+)',
				'youtu.be/(?<videoId>[a-z0-9_-]+)',
				'youtube-nocookie.com/embed/(?<videoId>[a-z0-9_-]+)'
			]
		});

		this.types.set('vimeo', {
			patterns: [
				'vimeo.com/.+'
			]
		});
	}


	/**
	 *	Provide a different VideoPlayerDriver based on the video URL
	 *
	 * @param {VideoPlayer} controller: the actual Player that is going to use the Driver
	 * @param {Element} element: DOM Element used to attach the player markup (usually whitin an iframe)
	 * @param {String} url: the Video URL
	 * @param {Object} [params]: all the parameters passed from the player to the driver
	 * @returns {VideoPlayerDriver | null} the specific driver created, if the URL matches a corresponding pattern, null otherwise
	 */
	getDriverByUrl(controller, element, url, params = {}) {
		for (const [type, entry] of this.types) {
			for (const pattern of entry.patterns) {
				const matches = namedRegexp(pattern, 'i').exec(url);
				const matched = (matches && matches.length > 0);
				if (matched) {
					const urlParams = matches.groups();
					params = Object.assign({}, params, urlParams);
					const driverName = ucFirst(type);
					const driver = this.factory.newInstance(driverName, {controller: controller, element: element, url: url, params: params});
					return driver;
				}
			}
		}
		return null;
	}


}


export default VideoPlayerDriverFactory;



// <?php
// namespace Xberg\Video;

// use Xberg\View\AbstractHelper;
// use Exception;


// class ViewHelper extends AbstractHelper
// {

// 	protected $options = [
// 		'template' => 'common/media/video',
// 		'types' => [
// 			'youtube' => [
// 				'defaultVars' => [
// 					'host' => 'youtube.com'
// 				],
// 				'patterns' => [
// 					'#(?<host>youtube.com)/embed/(?<id>[a-z0-9_-]+)#ix',
// 					'#(?<host>youtube-nocookie.com)/embed/(?<id>[a-z0-9_-]+)#ix',
// 					'#v=(?<id>[a-z0-9_-]+)#ix',
// 					'#youtu.be/(?<id>[a-z0-9_-]+)#ix'
// 				],
// 				'params' => [],
// 				'attributes' => [
// 					'src'                   => '//{{host}}/embed/{{id}}{{params}}',
// 					'frameborder'           => '0',
// 					'webkitAllowFullScreen' => 'true',
// 					'mozAllowFullScreen'    => 'true',
// 					'allowFullScreen'       => 'true',
// 					'width'                 => '100%',
// 					'height'                => '100%',
// 				]
// 			],

// 			'vimeo' => [
// 				'defaultVars' => [
// 				],
// 				'patterns' => [
// 					'#vimeo.com/(?<id>[0-9]+)#ix'
// 				],
// 				'params' => [],
// 				'attributes' => [
// 					'src'                   => '//player.vimeo.com/video/{{id}}{{params}}',
// 					'frameborder'           => '0',
// 					'webkitAllowFullScreen' => 'true',
// 					'mozAllowFullScreen'    => 'true',
// 					'allowFullScreen'       => 'true',
// 					'width'                 => '100%',
// 					'height'                => '100%',
// 				]
// 			]
// 		],
// 		'defaultOptions' => [
// 			'componentOptions' => [
// 				'component' => 'ExternalVideo',
// 				'linkAttribute' => 'externalVideoLink',
// 				'playerAttribute' => 'externalVideoPlayer'
// 			],
// 			'attributes' => [],
// 			'blockClass' => 'video',
// 			'modifiers' => [],
// 			'classes' => [],
// 			'wrapperClasses' => [],
// 			'data' => [],
// 			'img' => null,
// 			'imgOptions' => [],
// 			'linkClasses' => [],
// 			'linkData' => [],
// 			'playerClasses' => [],
// 			'playerData' => [],
// 			'caption' => null,
// 			'captionClasses' => [],
// 			'captionData' => [],
// 			'type' => null,
// 			'embedOnRequest' => true,
// 			'icon' => 'play',
// 			'iconClasses' => []
// 		]
// 	];


// 	public function externalVideo(string $url, array $options = [])
// 	{
// 		$options = array_replace($this->getOption('defaultOptions'), $options);

// 		$matches = null;
// 		$type = $options['type'];
// 		$type = $this->getType($url, $type, $matches);
// 		if ($type === null || $matches === null) {
// 			return '[No video type defined or video type not supported (' . $type . ') or wrong url format]';
// 		}
// 		$entry = $this->getOption('types')[$type];
// 		$vars = array_replace($entry['defaultVars'], $matches);
// 		if (count($entry['params'])) {
// 			$vars['params'] = '?' . http_build_query($entry['params']);
// 		}
// 		$keys = array_map(function ($key) { return '{{' . $key . '}}'; }, array_keys($vars));

// 		$attributes = array_replace($entry['attributes'], $options['attributes']);
// 		foreach ($attributes as $key => $value) {
// 			$attributes[$key] = str_replace($keys, $vars, $value);
// 		}

// 		$options['attributes'] = $attributes;
// 		$options['imgOptions']['onlyImg'] = true;
// 		return $this->view->capture($this->getOption('template'), ['url' => $url, 'options' => $options]);
// 	}


// 	public function youtube(string $url, array $options = [])
// 	{
// 		$options['type'] = 'youtube';
// 		return $this->externalVideo($url, $options);
// 	}


// 	public function vimeo(string $url, array $options = [])
// 	{
// 		$options['type'] = 'vimeo';
// 		return $this->externalVideo($url, $options);
// 	}


// 	protected function getType($url, $type = null, &$matches = null)
// 	{
// 		$types = $this->getOption('types');
// 		if ($type !== null) {
// 			if (!isset($types[$type])) {
// 				return null;
// 			}
// 			$types = array_intersect_key($types, [$type => 1]);
// 		}

// 		$type = null;
// 		foreach ($types as $name => $entry) {
// 			foreach ($entry['patterns'] as $pattern) {
// 				if (preg_match($pattern, $url, $matches)) {
// 					$type = $name;
// 					break 2;
// 				}
// 			}
// 		}
// 		return $type;
// 	}
// }

