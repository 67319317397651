import 'intersection-observer';
import {gsap} from 'gsap';
import domMixin from '../dom/dom-mixin';


class IntersectionTriggers extends domMixin() {

	constructor({
		// timeOffset = 200, // ms
		timeOffset = 100, // ms
		intersectionMargin = '-10px',
		// intersectionThreshold = 0.2,
		intersectionThreshold = 0.1,
		intersectionClass = 'inside'
	} = {}) {
		super();
		this.timeOffset = timeOffset;
		this.intersectionMargin = intersectionMargin;
		this.intersectionThreshold = intersectionThreshold;
		this.intersectionClass = intersectionClass;
	}


	init(element, itemSelectors) {
		this.itemSelectors = itemSelectors;
		this.intersectionObserver = new IntersectionObserver(this.onIntersection.bind(this), {
			rootMargin: `${this.intersectionMargin} 0px ${this.intersectionMargin} 0px`,
			threshold: [this.intersectionThreshold]
		});

		for (const selector of this.itemSelectors) {
			const items = element.querySelectorAll(selector);
			for (const item of items) {
				this.intersectionObserver.observe(item);
			}
		}
		return Promise.resolve();
	}


	destroy() {
		this.intersectionObserver.disconnect();
	}


	onIntersection(entries) {
		const timeline = gsap.timeline();
		let offset = 0;
		for (const entry of entries) {
			const target = entry.target;
			if (entry.isIntersecting) {
				timeline.call(() => {
					this.classList(target).add(this.intersectionClass);
				}, null, offset);
				offset += this.timeOffset / 1000;
			}
		}
		timeline.play();
	}

}


export default IntersectionTriggers;

