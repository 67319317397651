import BookingService from './booking-service';

class RedirectBookingService extends BookingService {
	constructor({url, dateFormat, lang}) {
		super();
		this.url = url;
		this.dateFormat = dateFormat;
		this.lang = lang;
	}

	/**
	 * @param {object} data fields: in, out, amount
	 * @param {PageComponent} bookingComponent the caller component
	 * @returns	{bool} false for preventing the submit event on the booking form, true otherwise
	 */
	request(data, bookingComponent) {
		const checkIn = this.dayjs(data.in).format(this.dateFormat);
		const checkOut = this.dayjs(data.out).format(this.dateFormat);
		const url = this.url.replace('{{lang}}', this.lang).replace('{{in}}', checkIn).replace('{{out}}', checkOut).replace('{{amount}}', data.amount);
		location.href = url;
		return false;
	}
}

export default RedirectBookingService;
