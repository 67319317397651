import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/fi';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {isString} from '../utils/types';
import {trim} from '../utils/string';
import {waitFrame} from '../utils/wait';
import PageComponent from '../component/page-component';

dayjs.extend(customParseFormat);

const names = {
	autodiscoverLocaleAttribute: 'autodiscoverLocale',
	dateFormatAttribute: 'dateFormat',
	dateAttribute: 'date',
	weekStartAttribute: 'weekStart',
	monthAttribute: 'month',
	monthContainerAttribute: 'monthContainer',
	monthActionAttribute: 'goToMonth',
	daysContainerAttribute: 'daysContainer',
	daysAttribute: 'days',
	dayAttribute: 'date',
	monthValueFormatAttribute: 'monthValueFormat',
	selectableAttribute: 'selectable',
	selectEventAttribute: 'selectEvent',
	changeEventAttribute: 'changeEvent',
	blurOnSelectAttribute: 'blurOnSelect',
	attrReplacementsAttribute: 'attrReplacements',
	selectedClassAttribute: 'selectedClass',
	selectionClassAttribute: 'selectionClass',
	selectionBeginClassAttribute: 'selectionBeginClass',
	selectionEndClassAttribute: 'selectionEndClass',
	selectionHighlightClassAttribute: 'selectionHighlightClass',
	enterClassAttribute: 'enterClass',
	leaveClassAttribute: 'leaveClass',
	backwardClassAttribute: 'backwardClass',
	forwardClassAttribute: 'forwardClass',
};

class Calendar extends PageComponent {
	constructor({
		root,
		element,
		autodiscoverLocale = true,
		dateFormat = 'YYYY-MM-DD',
		selectEvent = 'calendar:select',
		changeEvent = 'calendar:change',
		blurOnSelect = true,
		selectedClass = 'selected',
		selectionClass = 'selection',
		selectionBeginClass = 'selectionBegin',
		selectionEndClass = 'selectionEnd',
		selectionHighlightClass = 'selectionHighlight',
		enterClass = 'enter',
		leaveCalss = 'leave',
		backwardClass = 'dirBackward',
		forwardClass = 'dirForward',
	}) {
		super({root: root, element: element});
		this.names = names;

		this.defaults[names.autodiscoverLocaleAttribute] = autodiscoverLocale;
		this.defaults[names.dateFormatAttribute] = dateFormat;
		this.defaults[names.selectEventAttribute] = selectEvent;
		this.defaults[names.changeEventAttribute] = changeEvent;
		this.defaults[names.blurOnSelectAttribute] = blurOnSelect;
		this.defaults[names.selectedClassAttribute] = selectedClass;
		this.defaults[names.selectionClassAttribute] = selectionClass;
		this.defaults[names.selectionBeginClassAttribute] = selectionBeginClass;
		this.defaults[names.selectionEndClassAttribute] = selectionEndClass;
		this.defaults[names.selectionHighlightClassAttribute] = selectionHighlightClass;
		this.defaults[names.enterClassAttribute] = enterClass;
		this.defaults[names.leaveClassAttribute] = leaveCalss;
		this.defaults[names.backwardClassAttribute] = backwardClass;
		this.defaults[names.forwardClassAttribute] = forwardClass;

		this.locale = 'en';
		this.date = null;
		this.daysContainer = null;
		this.days = null;
		this.dayModel = null;
		this.dateFormat = null;
		this.allowedInterval = {
			begin: {value: null, included: true},
			end: {value: null, included: true},
		};
		this.selections = [];
		this.busy = false;
	}

	prepare() {
		const data = this.dataAttr().getAll();
		this.dateFormat = data[names.dateFormatAttribute];
		this.selectEvent = data[names.selectEventAttribute];
		this.changeEvent = data[names.changeEventAttribute];
		this.blurOnSelect = data[names.blurOnSelectAttribute];
		this.weekStart = data[names.weekStartAttribute];
		this.selectedClass = data[names.selectedClassAttribute];
		this.selectionClass = data[names.selectionClassAttribute];
		this.selectionBeginClass = data[names.selectionBeginClassAttribute];
		this.selectionEndClass = data[names.selectionEndClassAttribute];
		this.selectionHighlightClass = data[names.selectionHighlightClassAttribute];
		this.enterClass = data[names.enterClassAttribute];
		this.leaveClass = data[names.leaveClassAttribute];
		this.backwardClass = data[names.backwardClassAttribute];
		this.forwardClass = data[names.forwardClassAttribute];

		// try to discover the language looking for lang="..." in the ancestors
		if (data[names.autodiscoverLocaleAttribute]) {
			let item = this.element;
			while (item) {
				if (item.hasAttribute('lang')) {
					this.locale = item.getAttribute('lang');
					break;
				} else {
					item = item.parentNode;
				}
			}
		}
		this.date = names.dataAttribute in data ? dayjs(data[names.dataAttribute], this.dateFormat) : dayjs();
		this.daysContainer = this.element.querySelector(this.dataSelector(names.daysContainerAttribute));
		this.days = this.daysContainer.querySelector(this.dataSelector(names.daysAttribute));
		this.monthContainer = this.element.querySelector(this.dataSelector(names.monthContainerAttribute));
		this.month = this.monthContainer.querySelector(this.dataSelector(names.monthAttribute));

		// we assume that the first rendering is done server-side,
		// then we use the first day element as a model to generate the new ones on demand,
		// without needing a separated template
		this.dayModel = this.days.querySelector(this.dataSelector(names.dayAttribute)).cloneNode(true);
		this.listeners.monthAction = this.events.on(this.element, this.dataSelector(names.monthActionAttribute), 'click', this.onMonthAction.bind(this));
		this.listeners.select = this.events.on(this.element, this.dataSelector(names.selectableAttribute) + ':not(:disabled)', 'click', this.onSelect.bind(this));
	}

	onSelect(event, target) {
		const selectEvent = this.events.trigger(this.element, this.selectEvent, {
			component: this,
			selectedTarget: target,
			originalEvent: event,
		});
		if (!selectEvent.defaultPrevented) {
			if (this.blurOnSelect) {
				target.blur();
			}
			const previous = this.element.querySelector(this.classSelector(this.selectedClass));
			if (previous) {
				this.classList(previous).remove(this.selectedClass);
			}
			this.classList(target).add(this.selectedClass);
		}
	}

	onMonthAction(event, target) {
		target.blur();
		const action = this.dataAttr(target).get(names.monthActionAttribute);
		const refDate = this.date.startOf('month');
		let targetDate;
		switch (action) {
			case 'prev':
				targetDate = refDate.subtract(1, 'month');
				break;

			case 'next':
				targetDate = refDate.add(1, 'month');
				break;

			default:
				targetDate = dayjs(action, this.dateFormat).startOf('month');
		}

		if (!this.busy) {
			const changeEvent = this.events.trigger(this.element, this.changeEvent, {
				component: this,
				date: targetDate,
			});
			if (!changeEvent.defaultPrevented) {
				this.update(targetDate);
			}
		}
	}

	getSelectEvent() {
		return this.selectEvent;
	}

	getChangeEvent() {
		return this.changeEvent;
	}

	getDateFormat() {
		return this.dateFormat;
	}

	getDateAttribute() {
		return names.dateAttribute;
	}

	getLocale() {
		return this.locale;
	}

	setLocale(locale) {
		this.locale = locale;
	}

	getMonthDate() {
		return this.date;
	}

	/**
	 *
	 * @param {string} interval		possible formats:
	 * 								(beginDate, endDate)	excluding margins
	 * 								[beginDate, endDate]	including margins
	 * 								(beginDate, endDate]	excluding beginDate, including endDate
	 * 								(beginDate,)			only begin, open ended
	 * 								(, endDate)				only end
	 */
	setAllowedInterval(interval) {
		const parts = trim(interval).split(/\s*,\s*/gi);
		if (parts.length === 2) {
			let begin = parts[0];
			let end = parts[1];
			if (begin.length > 1) {
				const beginIncludedChar = begin.substr(0, 1);
				begin = trim(begin.substr(1));
				this.allowedInterval.begin.included = beginIncludedChar === '[';
				this.allowedInterval.begin.value = begin.length > 0 ? begin : null;
			} else {
				this.allowedInterval.begin.value = null;
			}
			const endIncludedChar = end.substr(end.length - 1);
			end = trim(end.substr(0, end.length - 1));
			this.allowedInterval.end.included = endIncludedChar === ']';
			this.allowedInterval.end.value = end.length > 0 ? end : null;
		} else {
			throw Error('invalid interval format: ' + interval);
		}
		this.updateAllowed(this.allowedInterval);
	}

	clearSelections() {
		for (const selection of this.selections) {
			for (const node of selection.nodes) {
				this.classList(node).remove(this.selectionClass, this.selectionBeginClass, this.selectionEndClass, this.selectionHighlightClass);
			}
		}
		this.selections = [];
	}

	clearSelectionsNodes() {
		for (const selection of this.selections) {
			selection.nodes = [];
		}
	}

	addSelection(beginDate, endDate, highlightBegin = true, highlightEnd = true) {
		if (beginDate !== null || endDate !== null) {
			const selection = {
				beginDate: beginDate,
				endDate: endDate,
				highlightBegin: highlightBegin,
				highlightEnd: highlightEnd,
				nodes: [],
			};
			this.selections.push(selection);
			let node = this.daysContainer.querySelector(this.dataSelector(names.dateAttribute));
			while (node) {
				if (node instanceof Element) {
					const isSelected = this.updateNodeSelection(node, beginDate, endDate, highlightBegin, highlightEnd);
					if (isSelected) {
						selection.nodes.push(node);
					}
				}
				node = node.nextSibling;
			}
		}
	}

	updateNodeSelection(node, beginDate, endDate, highlightBegin, highlightEnd) {
		let isSelected = false;
		if (beginDate !== null || endDate !== null) {
			const classList = this.classList(node);
			const dataAttr = this.dataAttr(node);
			const date = dataAttr.get(names.dateAttribute);
			if (beginDate !== null) {
				if (date >= beginDate) {
					if (endDate !== null && date <= endDate) {
						isSelected = true;
						classList.add(this.selectionClass);
					}
					if (date === beginDate) {
						isSelected = true;
						classList.add(this.selectionClass, this.selectionBeginClass);
						if (highlightBegin) {
							classList.add(this.selectionHighlightClass);
						}
						if (endDate === null) {
							classList.add(this.selectionEndClass);
						}
					}
				}
			}
			if (endDate !== null) {
				if (date <= endDate) {
					if (beginDate !== null && date >= beginDate) {
						isSelected = true;
						classList.add(this.selectionClass);
					}
					if (date === endDate) {
						isSelected = true;
						classList.add(this.selectionClass, this.selectionEndClass);
						if (highlightEnd) {
							classList.add(this.selectionHighlightClass);
						}
						if (beginDate === null) {
							classList.add(this.selectionBeginClass);
						}
					}
				}
			}
		}
		return isSelected;
	}

	// swap the list of days of the month with a new one
	update(date) {
		if (!this.busy) {
			this.busy = true;

			if (isString(date)) {
				date = dayjs(date, this.dateFormat);
			}

			const fragment = this.getMonthFragment(date);
			const newDays = this.days.cloneNode(false);
			const dirClass = date.isBefore(this.date) ? this.backwardClass : this.forwardClass;
			const currentClassList = this.classList(this.days);
			const newClassList = this.classList(newDays);

			const newMonth = this.month.cloneNode(true);
			const currentMonthClassList = this.classList(this.month);
			const newMonthClassList = this.classList(newMonth);
			const format = this.dataAttr(newMonth).get(names.monthValueFormatAttribute);
			newMonth.textContent = date.locale(this.locale).format(format);

			return waitFrame()
				.then(() => {
					currentClassList.add(this.leaveClass);
					newClassList.add(this.enterClass, dirClass);
					newDays.appendChild(fragment);
					this.daysContainer.appendChild(newDays);
					currentMonthClassList.add(this.leaveClass);
					newMonthClassList.add(this.enterClass, dirClass);
					this.monthContainer.appendChild(newMonth);
					return waitFrame();
				})
				.then(() => {
					currentClassList.add(dirClass);
					newClassList.remove(dirClass);
					currentMonthClassList.add(dirClass);
					newMonthClassList.remove(dirClass);
					return this.onTransitionEnd(newDays);
				})
				.then(() => {
					this.daysContainer.removeChild(this.days);
					newClassList.remove(this.enterClass);
					this.monthContainer.removeChild(this.month);
					newMonthClassList.remove(this.enterClass);
					this.days = newDays;
					this.month = newMonth;
					this.date = date;
					this.busy = false;
				});
		} else {
			return Promise.resolve();
		}
	}

	updateAllowed(allowedInterval) {
		for (const dayElement of this.daysContainer.querySelectorAll(this.dataSelector(names.dayAttribute))) {
			this.updateAllowedDay(dayElement, allowedInterval);
		}
	}

	updateAllowedDay(dayElement, allowedInterval) {
		const selectable = dayElement.querySelector(this.dataSelector(names.selectableAttribute));
		const date = this.dataAttr(dayElement).get(names.dateAttribute);
		const enabled =
			(allowedInterval.begin.value === null ||
				date > allowedInterval.begin.value ||
				(date === allowedInterval.begin.value && allowedInterval.begin.included === true)) &&
			(allowedInterval.end.value === null || date < allowedInterval.end.value || (date === allowedInterval.end.value && allowedInterval.end.included === true));
		selectable.disabled = !enabled;
	}

	getMonthFragment(date) {
		if (isString(date)) {
			date = dayjs(date, this.dateFormat);
		}
		this.clearSelectionsNodes();
		const fragment = document.createDocumentFragment();
		const firstDayOfWeek = parseInt(date.format('d'), 10);
		const firstOffset = firstDayOfWeek >= this.weekStart ? firstDayOfWeek - this.weekStart : 7 - this.weekStart + firstDayOfWeek;
		const baseDate = date;
		if (firstOffset > 0) {
			date = date.subtract(firstOffset, 'day');
		}
		let currentDate = date;
		for (let i = 0; i < 42; i++) {
			const dayElement = this.dayModel.cloneNode(true);
			const data = this.dataAttr(dayElement);

			// replacing attributes with patterns
			const items = Array.prototype.slice.call(dayElement.querySelectorAll(this.dataSelector(names.attrReplacementsAttribute)));
			if (data.has(names.attrReplacementsAttribute)) {
				items.push(dayElement);
			}
			for (let j = 0; j < items.length; j++) {
				const item = items[j];
				const replacements = this.dataAttr(item).get(names.attrReplacementsAttribute, null);
				if (replacements !== null) {
					for (const attrName in replacements) {
						if (replacements.hasOwnProperty(attrName)) {
							const newValue = this.processAttrValue(replacements[attrName], currentDate, baseDate);
							if (attrName === 'content') {
								item.textContent = newValue;
							} else {
								item.setAttribute(attrName, newValue);
							}
						}
					}
				}
			}
			this.updateAllowedDay(dayElement, this.allowedInterval);
			for (const selection of this.selections) {
				const isSelected = this.updateNodeSelection(dayElement, selection.beginDate, selection.endDate, selection.highlightBegin, selection.highlightEnd);
				if (isSelected) {
					selection.nodes.push(dayElement);
				}
			}
			fragment.appendChild(dayElement);
			currentDate = currentDate.add(1, 'day');
		}
		return fragment;
	}

	processAttrValue(value, currentDate, baseDate) {
		return value.replace(/\{\{([^}]+)\}\}/gi, (full, format) => {
			let result;
			const baseMonth = baseDate.format('YYYY-MM');
			const currentMonth = currentDate.format('YYYY-MM');
			switch (format) {
				case 'isToday':
					result = currentDate.format(this.dateFormat) === dayjs().format(this.dateFormat) ? 'true' : 'false';
					break;
				case 'relativeMonth':
					result = currentMonth === baseMonth ? 'current' : currentMonth < baseMonth ? 'prev' : 'next';
					break;
				default:
					result = currentDate.locale(this.locale).format(format);
			}
			return result;
		});
	}
}

export default Calendar;
